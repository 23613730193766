import { auth } from "../firebase";
import {
  updateProfile,
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from "firebase/auth";

// SignUp users using their Email and Password
export const emailPasswordSignUp = async (name, mail, pass) => {
  const res = await createUserWithEmailAndPassword(auth, mail, pass)
    .then(() => {
      updateProfile(auth.currentUser, { displayName: name }).then(() =>
        sendEmailVerification(auth.currentUser).then((res) => {
          return res;
        })
      );
    })
    .catch((err) => {
      return err.message;
    });

  return res;
};

export const updateName = async (nameOne, nameTwo) => {
  const res = await updateProfile(auth.currentUser, {
    displayName: nameOne || nameTwo,
  });

  return res;
};
